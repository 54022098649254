<template>
  <div>
    <v-form @submit.prevent="submitResponse">
      <p class="mb-3">
        For the compounds below, write appropriate acid-base equilibrium reactions and use them to
        determine whether each of the solutions would be <b>acidic</b>, <b>basic</b>, or
        <b>neutral</b>. Make sure to indicate the phase of reactants and products. The equilibrium
        arrow should be denoted by '&lt;=&gt;'.
      </p>

      <p class="mb-2">a) <chemical-latex content="HCOOH" /></p>

      <chemical-notation-input
        v-model="inputs.HCOOHrxn"
        dense
        :show-note="false"
        class="mb-3"
        :disabled="!allowEditing"
      />

      <v-select
        v-model="inputs.pHHCOOHsoln"
        class="d-sm-inline-block mb-5"
        style="width: 160px"
        dense
        :items="acidityItems"
        item-text="text"
        item-value="value"
        placeholder="pH"
      />

      <p class="mb-2">b) <chemical-latex content="NaCN" /></p>

      <chemical-notation-input
        v-model="inputs.NaCNrxn"
        dense
        :show-note="false"
        class="mb-3"
        :disabled="!allowEditing"
      />

      <v-select
        v-model="inputs.pHNaCNsoln"
        class="d-sm-inline-block mb-5"
        style="width: 160px"
        dense
        :items="acidityItems"
        item-text="text"
        item-value="value"
        placeholder="pH"
      />

      <p class="mb-2">c) <chemical-latex content="(CH3CH2)2NH" /></p>

      <chemical-notation-input
        v-model="inputs.Et2NHrxn"
        dense
        :show-note="false"
        class="mb-3"
        :disabled="!allowEditing"
      />

      <v-select
        v-model="inputs.pHEt2NHsoln"
        class="d-sm-inline-block mb-5"
        style="width: 160px"
        dense
        :items="acidityItems"
        item-text="text"
        item-value="value"
        placeholder="pH"
      />

      <p class="mb-2">d) <chemical-latex content="KNO2" /></p>

      <chemical-notation-input
        v-model="inputs.KNO2rxn"
        dense
        :show-note="false"
        class="mb-3"
        :disabled="!allowEditing"
      />

      <v-select
        v-model="inputs.pHKNO2soln"
        class="d-sm-inline-block mb-0"
        style="width: 160px"
        dense
        :items="acidityItems"
        item-text="text"
        item-value="value"
        placeholder="pH"
      />
    </v-form>
  </div>
</template>

<script>
import DynamicQuestionMixin from '../../mixins/dynamic-question';
import CalculationInput from '../inputs/CalculationInput';
import ChemicalLatex from '../displayers/ChemicalLatex';
import StembleLatex from '@/tasks/components/StembleLatex';
import ChemicalNotationInput from '@/chemical-equations/components/ChemicalNotationInput.vue';
import NumberValue from '@/tasks/components/NumberValue.vue';

export default {
  name: 'Question468',
  components: {
    NumberValue,
    ChemicalNotationInput,
    CalculationInput,
    ChemicalLatex,
    StembleLatex,
  },
  mixins: [DynamicQuestionMixin()],
  data() {
    return {
      inputs: {
        HCOOHrxn: null,
        pHHCOOHsoln: null,
        NaCNrxn: null,
        pHNaCNsoln: null,
        Et2NHrxn: null,
        pHEt2NHsoln: null,
        KNO2rxn: null,
        pHKNO2soln: null,
      },
      acidityItems: [
        {text: 'Acidic', value: 'acidic'},
        {text: 'Basic', value: 'basic'},
        {text: 'Neutral', value: 'neutral'},
      ],
    };
  },
};
</script>
